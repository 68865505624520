/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DocumentType {
    UNKNOWN = 'unknown',
    LEASE = 'lease',
    INVOICE = 'invoice',
    TRANSACTION = 'transaction',
    VARIATION = 'variation',
    ASSIGNMENT = 'assignment',
    SUBLEASE = 'sublease',
    TRANSFER = 'transfer',
    INCENTIVE = 'incentive',
    DISCLOSURE_STATEMENT = 'disclosureStatement',
    AGREEMENT_TO_LEASE = 'agreementToLease',
    LIQUOR_LICENSE = 'liquorLicense',
    LICENSE_TO_OCCUPY = 'licenseToOccupy',
    RENEWAL_DEED = 'renewalDeed',
    SURRENDER = 'surrender',
    HEADS_OF_AGREEMENT = 'headsOfAgreement',
    STORAGE_LICENSE = 'storageLicense',
    CAVEAT = 'caveat',
    SIDE_DEED = 'sideDeed',
    OPTIONS_LEASE = 'optionsLease',
    FRANCHISE_AGREEMENT = 'franchiseAgreement',
    OTHER = 'other',
    RIGHT_OF_ENTRY = 'rightOfEntry',
    DA_APPROVAL = 'daApproval',
    FITOUT_CONTRIBUTION_AGREEMENT = 'fitoutContributionAgreement',
    SEATING_AREA_LICENSE = 'seatingAreaLicense',
    ATM_LICENSE = 'atmLicense',
    LICENSE = 'license',
    EXPIRED_LEASE = 'expiredLease',
    OPTION_EXERCISE = 'optionExercise',
    EXTENSION = 'extension',
    CAR_PARK_LICENSE = 'carParkLicense',
}
